
@font-face {
  font-family: Minecraft;
  src: url('./image/Minecraft.ttf');
}
.def {
  position: relative;
}
.def::after{
  content: '';
  position: absolute;
  z-index: 1000;
  height: 200px;
  width: 256px;
  background-position: 0px -0px;
  background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1675902623/KOD/pruebas/7_rfgsa2.png');
  animation: animacion4 0.5s steps(4) infinite;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes animacion4 {
  0%{
    background-position: 0px -0px;
    
  }

  100%{
      background-position:-886px -886px ;
      
  }
}
.caballo{
  background-color: rgba(0,0,0,0.3);
  position: relative;
}

.caballo::after{
  
  content: '';
  position: absolute;
  
  width: 200px;
  height: 225px;
  background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1675970159/KOD/pruebas/lanza_1_1_pg0o6c.png'); 
  /* animation: animacion2 1s steps(6) infinite; */
 

  background-size: contain;
  background-repeat: no-repeat;
  z-index: 130000;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
@keyframes animacion2 {
  0%{
    background-position: 0px;
    
  }

  100%{
      background-position:-980px;
      
  }
}
@keyframes animacion {
  0%{
    background-position: 0px;
    
  }

  100%{
      background-position:-506px;
  }
}
.espada{


  position: relative;
}
.espada::before{
  
  content: '';
  position: absolute;

  top: 50%;
  left: 50%;
  
  width: 200px;
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
 
  /* background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1673376008/KOD/sprites_cfc4fw.png');  */
  /* animation: animacion 1s steps(6) infinite; */
  

  z-index: 130000;
  opacity: 1;
 

}
.espada::after{
  
  content: '';
  position: absolute;

  top: 50%;
  left: 50%;
  
  width: 200px;
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1675695473/KOD/pruebas/lanza_m2bs88.png'); 
  /* background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1673376008/KOD/sprites_cfc4fw.png');  */
  /* animation: animacion 1s steps(6) infinite; */
  

  z-index: 130000;
  opacity: 1;
 

}
@keyframes animacion {
  0%{
    background-position: 0px;

  }

  100%{
      background-position:-506px;
     
  }
}

.flecha{
  background-color: rgba(0,0,0,0.3);
  position: relative;
  filter: grayscale(50%);
  transform: scale(1.1);
}
.flecha::after{
  
  content: '';
  position: absolute;
  
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1675793517/KOD/pruebas/flechita_1_1_rehrlc.png'); 

  /* background-image: url('https://res.cloudinary.com/dygipivad/image/upload/v1675624564/KOD/pruebas/d5j0dry-956ba9d3-ca0f-4aca-af53-b95a788bd12d_1_keth64.gif'); 
  animation: animacion 1s steps(9) infinite;
   */

  z-index: 130000;
  opacity: 1;
  top: 50%;
  left: 50%;

  width: 190px;
  height: 115px;
  

}
@keyframes animacion3 {
  0%{
    background-position: 0px;
    
  }

  100%{
      background-position:-500px;
  }
}

.envoltorio{
  position: relative;
  transform-style: preserve-3d;
  transform: scaleX(1) scaleY(0.7) scaleZ(1) rotateX(30deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
}
.envoltorioImage{
  position: relative;
  transform-style: preserve-3d;
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(10deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(-55px) translateZ(-100px) skewX(0deg) skewY(0deg);
}
.contenedor-3d{
  width: 100%;
  height: 45%;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  display: flex;
  justify-content: space-evenly;
}
.contenedor-3dImage{
  width: 90%;
  height: 90%;
  perspective: 1400px;
  perspective-origin: 50% 50%;

}
#root{
  width: 100%;
  height: 100%;
  background-image: url('https://img.freepik.com/foto-gratis/galaxy-espacio-textura-fondo_53876-143060.jpg?w=1800&t=st=1675958606~exp=1675959206~hmac=ae6df61dc9789efd358eafbf2f8da4017564972e365e51c30940f1a5e36658a6');
}


